import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AudioVisualizerService } from '@services/audio-visualizer.service';
import { LoggerService } from '@services/logger.service';

@Component({
  selector: 'app-audio-visualizer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './audio-visualizer.component.html',
  styleUrls: ['./audio-visualizer.component.scss'],
})
export class AudioVisualizerComponent implements OnInit, OnDestroy {
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  private audioContext!: AudioContext;
  private analyser!: AnalyserNode;
  private mediaStream!: MediaStream;
  private filter!: BiquadFilterNode;
  color: string = 'white';

  constructor(
    private visualizerService: AudioVisualizerService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.setupAudioContext();
    this.visualizerService.currentColor.subscribe((color) => {
      this.color = color;
      this.draw(); // Redraw with new color whenever it changes
    });
  }

  setupAudioContext() {
    navigator.mediaDevices
      .getUserMedia({ audio: { noiseSuppression: true } })
      .then((stream) => {
        this.mediaStream = stream;
        this.audioContext = new AudioContext();
        const source = this.audioContext.createMediaStreamSource(stream);

        // Create a BiquadFilterNode for noise filtration
        this.filter = this.audioContext.createBiquadFilter();
        this.filter.type = 'lowpass'; // You can change this to 'bandpass' if needed
        this.filter.frequency.value = 1000; // Adjust frequency to filter noise

        // Create an analyser node
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 2048;

        // Connect the nodes: source -> filter -> analyser -> destination
        source.connect(this.filter);
        this.filter.connect(this.analyser);

        // Adjust canvas for high DPI displays
        this.adjustCanvas();

        // Start drawing the visualizer
        this.draw();
      })
      .catch((e) =>
        this.loggerService.displayLog('Error accessing microphone:' + e)
      );
  }

  adjustCanvas() {
    const canvasEl = this.canvas.nativeElement;
    const dpr = window.devicePixelRatio || 1;
    canvasEl.width = canvasEl.clientWidth * dpr;
    canvasEl.height = canvasEl.clientHeight * dpr;

    const canvasCtx = canvasEl.getContext('2d');
    if (canvasCtx) {
      canvasCtx.scale(dpr, dpr);
    }
  }

  draw() {
    if (!this.canvas?.nativeElement || !this.analyser) return;

    requestAnimationFrame(() => this.draw());
    const canvasCtx = this.canvas.nativeElement.getContext('2d')!;
    const width = this.canvas.nativeElement.clientWidth;
    const height = this.canvas.nativeElement.clientHeight;

    canvasCtx.clearRect(0, 0, width, height);

    const bufferLength = this.analyser.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    this.analyser.getByteTimeDomainData(dataArray);

    const centerX = width / 2;
    const baseY = height - 10; // Base position of the dots at the bottom
    const dotRadius = 5; // Radius of each dot in idle state
    const numDots = 4; // Number of dots
    const dotSpacing = 15; // Spacing between dots
    const amplitudeScale = 0.75; // Scale the amplitude

    // Calculate the total width occupied by all dots and spaces
    const totalWidth = (numDots - 1) * dotSpacing + numDots * dotRadius * 2;

    for (let i = 0; i < numDots; i++) {
      const x =
        centerX - totalWidth / 2 + dotRadius + i * (dotRadius * 2 + dotSpacing);
      const amplitude = Math.max(
        0,
        (dataArray[i * (bufferLength / numDots)] - 128) * amplitudeScale
      );
      const barHeight = dotRadius + amplitude; // Height of the bar extending from the dot

      // Draw the base circle
      canvasCtx.beginPath();
      canvasCtx.arc(x, baseY, dotRadius, 0, Math.PI * 2);
      canvasCtx.fillStyle = this.color;
      canvasCtx.fill();
      canvasCtx.closePath();

      // Draw the bar extending upwards from the base circle
      if (barHeight > dotRadius) {
        canvasCtx.beginPath();
        canvasCtx.moveTo(x - dotRadius, baseY);
        canvasCtx.lineTo(x - dotRadius, baseY - barHeight + dotRadius);
        canvasCtx.arcTo(
          x - dotRadius,
          baseY - barHeight,
          x,
          baseY - barHeight,
          dotRadius
        );
        canvasCtx.arcTo(
          x + dotRadius,
          baseY - barHeight,
          x + dotRadius,
          baseY - barHeight + dotRadius,
          dotRadius
        );
        canvasCtx.lineTo(x + dotRadius, baseY);
        canvasCtx.fillStyle = this.color;
        canvasCtx.fill();
        canvasCtx.closePath();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.audioContext) {
      this.audioContext.close();
    }
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  }
}

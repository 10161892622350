<div
  class="main-container min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-[#03045E] to-[#0077B6] p-4"
>
  <div class="absolute top-0 left-0 p-4">
    <img
      src="assets/images/viitorcloud-logo.png"
      alt="Company Logo"
      class="h-8"
    />
  </div>
  <div class="absolute bottom-0 right-0 p-4 flex text-[#CAF0F8] gap-2">
    Powered by
    <img class="h-4" src="assets/images/svg/haxi_ai-logo.svg" alt="Haxi Logo" />
  </div>

  <!-- Landing Page -->
  <div
    *ngIf="currentStep === 'landing'"
    class="flex flex-col items-center text-center text-white"
  >
    <img
      src="assets/images/webp/survey-design.webp"
      alt="Survey"
      class="h-36 mb-4"
    />
    <h1 class="text-4xl font-bold mb-4">Welcome to Our Survey</h1>
    <p class="mb-4">
      This survey will take approximately 5 minutes to complete. Please read the
      instructions carefully before starting.
    </p>
    <button
      (click)="startSurvey()"
      [disabled]="!textToSpeakReady"
      class="enhanced-button w-48 flex justify-center items-center bg-[#00B4D8] text-white px-4 py-3 rounded-lg hover:bg-[#CAF0F8] hover:text-[#0077b6] transition"
    >
      @if (textToSpeak) {
        Start Your Survey
      } @else {
        <div class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
      }
    </button>
  </div>

  <!-- Survey Questions -->
  <div *ngIf="currentStep === 'question'" class="w-full max-w-4xl flex gap-4">
    <!-- Block 1 -->
    <div
      class="block-1 flex flex-col items-center justify-center bg-transparent text-white rounded-lg p-4"
    >
      <button
        type="button"
        (click)="startRecording()"
        class="h-28 cursor-pointer"
      >
        <img
          src="assets/images/svg/fancy-mic.svg"
          alt="Question Icon"
          class="h-28"
        />
      </button>
    </div>

    <!-- Block 2 -->
    <div class="block-2 w-full bg-white rounded-lg p-6 shadow-lg">
      <div class="flex justify-between items-center mb-4 text-[#03045E]">
        <p class="text-lg">
          Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}
        </p>
      </div>
      <h2 class="text-3xl font-bold mb-6 text-[#03045E]">
        {{ questions[currentQuestionIndex].question }}
      </h2>
      <div class="grid grid-cols-1 gap-4 mb-6">
        <div
          tabindex="-1"
          (keydown)="selectOption(option)"
          *ngFor="let option of questions[currentQuestionIndex].options"
          (click)="selectOption(option)"
          [ngClass]="{
            'selected-option': selectedOption === option,
            'unselected-option': selectedOption !== option
          }"
          class="options-card cursor-pointer p-4 rounded-lg shadow hover:bg-[#CAF0F8] hover:text-gray-900 transition"
        >
          {{ option }}
        </div>
      </div>
      <div class="flex justify-between w-full">
        <button
          *ngIf="currentQuestionIndex > 0"
          (click)="previousQuestion()"
          class="hidden enhanced-button bg-[#0077B6] text-white p-2 rounded-full hover:bg-[#00B4D8] transition"
        >
          <img
            class="h-6"
            src="assets/images/svg/previous-arrow.svg"
            alt="Previous"
          />
        </button>
        <div class="flex-grow"></div>
        <button
          (click)="nextQuestion()"
          class="enhanced-button bg-[#0077B6] text-white p-2 rounded-full hover:bg-[#00B4D8] transition"
        >
          <img class="h-6" src="assets/images/svg/next-arrow.svg" alt="Next" />
        </button>
      </div>
    </div>
  </div>

  <!-- Survey Summary -->
  <div
    *ngIf="currentStep === 'summary'"
    class="w-full max-w-4xl bg-transparent rounded-lg p-6 shadow-2xl text-[#03045E]"
  >
    <h2 class="text-3xl font-bold mb-6 text-center text-[#90e0ef]">
      Survey Summary
    </h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
      <div
        *ngFor="let answer of answers"
        class="summary-card p-4 rounded-lg shadow-md"
      >
        <h3 class="font-semibold">{{ answer.question }}</h3>
        <p>{{ answer.answer ? answer.answer : "Not answered" }}</p>
      </div>
    </div>
    <div class="flex justify-center">
      <button
        (click)="submitSurvey()"
        class="enhanced-button border-2 border-[#fff] bg-[#0077B6] text-white px-4 py-2 rounded-lg hover:bg-[#00B4D8] transition"
      >
        Submit
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="!isPermission && isActiveStream">
  <div class="permission-model">
    <div class="model-content">
      <p>
        Will you allow <span>{{ baseUrl }}</span> to use your microphone?
      </p>
      <img width="120" height="" src="assets/images/mic-animation.gif" alt="" />
      <p>Microphone to share:</p>
      <button class="common-button" (click)="getMicPermission()">Ok</button>
    </div>
  </div>
</ng-container>

import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DELAY_TIME, MAGIC_NUMBERS } from '@constants/common';
import { TypeRes } from '@models/speech-recognition.model';
import { LoggerService } from '@services/logger.service';
import { NotificationService } from '@services/notification.service';
import { TextToSpeechService } from '@services/text-to-speech.service';
import { VoiceRecognitionService } from '@services/voice-recognition.service';
import { WebSocketService } from '@services/web-socket.service';
import { Subject, delay, of } from 'rxjs';

interface Question {
  question: string;
  options: string[];
}

@Component({
  selector: 'app-smart-survey',
  standalone: true,
  imports: [NgFor, NgIf, NgClass],
  templateUrl: './smart-survey.component.html',
  styleUrls: ['./smart-survey.component.scss'],
})
export class SmartSurveyComponent implements OnInit, OnDestroy {
  currentStep: 'landing' | 'question' | 'summary' = 'landing';
  questions: Question[] = [
    {
      question: 'Please select your age group.',
      options: ['A. Under 21', 'B. 21 to 40', 'C. 41 to 60', 'D. Above 60'],
    },
    {
      question: 'How often do you use our products and services?',
      options: ['A. Daily', 'B. Weekly', 'C. Monthly', 'D. Yearly'],
    },
    {
      question:
        'How well did our products and services meet your expectations?',
      options: [
        'A. Extremely well',
        'B. Somewhat well',
        'C. Not so well',
        "D. Can't say",
      ],
    },
    {
      question: 'Which product do you like the most?',
      options: [
        'A. EveryTicket',
        'B. Digital Humans',
        'C. Cuenect',
        'D. EveryCred',
      ],
    },
    {
      question:
        'Overall, how satisfied or dissatisfied are you with our company?',
      options: [
        'A. Very satisfied',
        'B. Somewhat satisfied',
        'C. Neutral',
        'D. Strongly dissatisfied',
      ],
    },
    {
      question:
        'How likely is it that you would recommend our product to your connections?',
      options: [
        'A. Very likely',
        'B. Somewhat likely',
        'C. Neutral',
        'D. Very unlikely',
      ],
    },
  ];
  currentQuestionIndex: number = 0;
  selectedOption: string | null = null;
  answers: {
    question: string;
    answer: string | null;
  }[] = [];
  isPermission = false;
  voiceInput: string;
  showPopup = true;
  isActiveStream = true;
  baseUrl = '';
  isDecline: boolean;
  textToSpeak: Blob;
  textToSpeakReady = false;

  private destroy$ = new Subject<void>();
  private currentAudio: HTMLAudioElement | null = null;

  constructor(
    private loggerService: LoggerService,
    private notificationService: NotificationService,
    private voiceRecognition: VoiceRecognitionService,
    private webSocketService: WebSocketService,
    private ttsService: TextToSpeechService
  ) {}

  ngOnInit(): void {
    this.baseUrl = window.location.origin;
    this.isPermission = JSON.parse(
      localStorage.getItem('userVisited') ?? 'false'
    );
    this.showPopup = false;
    this.welcomeMessage();
    this.connectWebSocket();
    this.getSocketInformation();
  }

  ngOnDestroy(): void {
    localStorage.removeItem('userVisited');
    this.cancelOngoingRequests();
  }

  private welcomeMessage() {
    const welcomeText = `Welcome to our product survey!
    This survey will take approximately 5 minutes to complete.
    Please read the instructions carefully and Start the survey.`;

    this.ttsService.generateSpeech(welcomeText).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.introFirstQuestion),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  private introFirstQuestion() {
    const introText = `Hey there! Let's start with our first question. ${this.questions[0].question}
    and the options are ${this.questions[0].options}`;
    this.textToSpeech(introText);
  }

  private textToSpeech(text: string) {
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => {
        this.textToSpeak = blob;
        this.textToSpeakReady = true;
      },
      error: (err) => {
        this.loggerService.displayLog(err);
        this.textToSpeakReady = false;
      },
    });
  }

  private playAudioBlob(blob: Blob, onEndedCallback?: () => void) {
    this.stopCurrentAudio();
    const url = URL.createObjectURL(blob);
    const audio = new Audio(url);
    this.currentAudio = audio;
    audio.play();
    if (onEndedCallback) {
      audio.onended = onEndedCallback;
    }
  }

  private connectWebSocket(): void {
    this.webSocketService.connectSmartSurvey('audio');
  }

  startSurvey() {
    this.currentStep = 'question';
    this.haxiSpeaking();
  }

  haxiSpeaking() {
    this.playAudioBlob(this.textToSpeak, this.startRecording.bind(this));
    this.textToSpeakReady = false;
  }

  selectOption(option: string) {
    this.selectedOption = option;
  }

  nextQuestion() {
    this.stopCurrentAudio();
    this.saveAnswer();
    this.moveToNextQuestion();
  }

  previousQuestion() {
    this.stopCurrentAudio();
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.answers.pop();
    }
  }

  submitSurvey() {
    this.notificationService.showNotification(
      '🎉 Survey submitted successfully! 🎉'
    );
    const text = `Survey Submitted Successfully.`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) =>
        this.playAudioBlob(blob, () => (this.currentStep = 'landing')),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  startRecording() {
    if (!this.isPermission) return;
    this.loggerService.displayLog('Recording Started', 'log');
    of({})
      .pipe(delay(DELAY_TIME['500_MS']))
      .subscribe(() => {
        this.voiceRecognition.handleRecording(this.destroy$).subscribe({
          next: (transcript) => {
            this.voiceInput = this.processInput(transcript);
            this.submitSpeech();
          },
          error: (err) => this.loggerService.displayLog(err),
          complete: () => this.stopRecording(),
        });
      });
  }

  submitSpeech() {
    if (!this.voiceInput.length) return;
    this.webSocketService.send({
      question: `Question: ${this.questions[this.currentQuestionIndex].question}
      Options: ${this.questions[this.currentQuestionIndex].options}
      User: ${this.voiceInput}`,
    });
  }

  getSocketInformation() {
    this.webSocketService.socket$.subscribe({
      next: (res: TypeRes) => {
        this.stopRecording();
        this.handleResponse(res.answer as string);
      },
      error: (error) => {
        this.loggerService.displayLog(error);
        this.stopRecording();
      },
    });
  }

  handleResponse(response: string) {
    switch (response) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
        this.selectOption(
          this.questions[this.currentQuestionIndex].options[
            response.charCodeAt(0) - 'A'.charCodeAt(0)
          ]
        );
        this.completeQuestion();
        break;
      case 'none':
      default:
        this.askAgain();
        break;
    }
  }

  completeQuestion() {
    const lastChunk = this.getLastChunkText();
    const text = `You have selected option ${this.selectedOption}, ${lastChunk}`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.nextQuestion.bind(this)),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  askAgain() {
    const text = `I didn't catch that.
    Could you please repeat your answer for the question: ${this.questions[this.currentQuestionIndex].question}?`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.startRecording.bind(this)),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  stopRecording() {
    this.voiceInput = '';
    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  cancelOngoingRequests() {
    this.destroy$.next();
  }

  getMicPermission() {
    navigator.mediaDevices
      .getUserMedia({ audio: { noiseSuppression: true } })
      .then((stream) => {
        this.isActiveStream = stream.active;
        this.isPermission = this.isActiveStream;
        this.isDecline = false;
        localStorage.setItem('userVisited', JSON.stringify(this.isPermission));
        this.reloadModal();
      })
      .catch((error) => {
        this.isPermission = false;
        this.isDecline = true;

        this.loggerService.displayLog(error);
      });
  }

  reloadModal() {
    location.reload();
  }

  processInput(input: string): string {
    return input.replace(/\$/g, '');
  }

  private stopCurrentAudio() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
      this.cancelOngoingRequests();
    }
  }

  private saveAnswer() {
    this.answers.push({
      question: this.questions[this.currentQuestionIndex].question,
      answer: this.selectedOption,
    });
    this.selectedOption = null;
  }

  private moveToNextQuestion() {
    this.currentQuestionIndex++;
    if (this.currentQuestionIndex >= this.questions.length) {
      this.showSummary();
    } else {
      this.askNextQuestion();
    }
  }

  private showSummary() {
    this.currentStep = 'summary';
    const text = `The next is summary. Please review your answers.`;
    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) =>
        this.playAudioBlob(blob, () => {
          const text = `Please submit your survey. Thank you for your time.`;
          this.ttsService.generateSpeech(text).subscribe({
            next: (blob) => this.playAudioBlob(blob),
            error: (err) => this.loggerService.displayLog(err),
          });
        }),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  private askNextQuestion() {
    const isLastQuestion =
      this.currentQuestionIndex === this.questions.length - 1;
    const text = isLastQuestion
      ? `Now, the last question is, ${this.questions[this.currentQuestionIndex].question}
      and the options are ${this.questions[this.currentQuestionIndex].options}.`
      : `The next question is, ${this.questions[this.currentQuestionIndex].question}
      and the options are ${this.questions[this.currentQuestionIndex].options}.`;

    this.ttsService.generateSpeech(text).subscribe({
      next: (blob) => this.playAudioBlob(blob, this.startRecording.bind(this)),
      error: (err) => this.loggerService.displayLog(err),
    });
  }

  private getLastChunkText(): string {
    if (this.currentQuestionIndex === this.questions.length - 1) {
      return 'lets check your submitted answers.';
    } else if (
      this.currentQuestionIndex ===
      this.questions.length - MAGIC_NUMBERS['2']
    ) {
      return 'lets go to the last question';
    } else {
      return 'lets continue with our next question.';
    }
  }

  onAllowAudio() {
    this.showPopup = false;
    this.startSurvey();
  }

  onDenyAudio() {
    this.showPopup = false;
  }
}

<div class="page-wrapper">
  <div class="app-container">
    <div class="left-section">
      <!-- Logo -->
      <div class="logo">
        <a href="https://www.viitorcloud.com" target="_blank">
          <img src="assets/images/viitorcloud-logo.png" alt="Logo" />
        </a>
      </div>

      <!-- Greeting Block -->
      <div *ngIf="currentState === 'listening'" class="greeting">
        <p class="listening-loader"></p>
      </div>
      <div *ngIf="currentState === 'thinking'" class="greeting">
        <p class="thinking-loader"></p>
      </div>

      <!-- Conditional Blocks Based on State -->
      <ng-container [ngSwitch]="currentState">
        <!-- Microphone Button for 'welcome' and 'none' states -->
        <ng-container *ngSwitchCase="'welcome'">
          <div
            *ngIf="currentState === 'welcome' || currentState === 'none'"
            class="mic-container"
          >
            <button class="mic-button" (click)="startRecording()">
              <img
                src="assets/images/svg/material-symbols_mic_rbg.svg"
                alt="Microphone Icon"
              />
              <p>Click to Start</p>
              <span class="tooltip">
                Say Hello to
                <p>"Haxi"</p>
              </span>
            </button>
          </div>
        </ng-container>

        <!-- Microphone Button for 'none' state -->
        <ng-container *ngSwitchCase="'none'">
          <div
            *ngIf="currentState === 'welcome' || currentState === 'none'"
            class="mic-container"
          >
            <button class="mic-button" (click)="startRecording()">
              <img
                src="assets/images/svg/material-symbols_mic_rbg.svg"
                alt="Microphone Icon"
              />
              <p>Click to Start</p>
              <span class="tooltip">
                Say Hello to
                <p>"Haxi"</p>
              </span>
            </button>
          </div>
        </ng-container>

        <!-- Listening Block -->
        <div
          *ngSwitchCase="'listening'"
          class="input-loader center-content listening-block"
        >
          <app-audio-visualizer></app-audio-visualizer>
          <button
            type="button"
            (click)="completeRecording()"
            class="stop-listening input-button"
          >
            Stop Listening
          </button>
        </div>

        <!-- Thinking Block -->
        <div *ngSwitchCase="'thinking'" class="thinking">
          <span class="circle1"></span>
          <span class="circle2"></span>
          <span class="circle3"></span>
        </div>

        <!-- Speaking Block -->
        <div *ngSwitchCase="'speaking'" class="input-loader">
          <ul class="wave-menu">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <button class="stop-listening input-button" (click)="stopAudio()">
            Stop Responding
          </button>
        </div>
      </ng-container>

      <!-- Powered by Section -->
      <div class="powered-by">
        <p>Powered by</p>
        <a href="https://chat.haxi.ai/" target="_blank">
          <img
            class="haxi-logo"
            src="assets/images/svg/haxi_ai-logo.svg"
            alt="Haxi Logo"
          />
        </a>
      </div>
    </div>

    <!-- Right Section -->
    <div class="right-section">
      <!-- Conditional Blocks Based on State -->
      <ng-container [ngSwitch]="currentState">
        <!-- Suggestions Block -->
        <div *ngSwitchCase="'welcome'" class="suggestions hide-scrollbar">
          <div class="suggestion-list" #suggestionList1>
            <div
              tabindex="-1"
              class="list-box"
              *ngFor="let message of starterMessages"
              (click)="setInputText(message)"
              (keydown)="setInputText(message)"
            >
              {{ message }}
            </div>
          </div>
          <div class="suggestion-list" #suggestionList2>
            <div
              tabindex="-1"
              class="list-box"
              *ngFor="let message of starterMessages.reverse()"
              (click)="setInputText(message)"
              (keydown)="setInputText(message)"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Chat History Block -->
      <div
        class="chat-history"
        [ngClass]="{ hidden: currentState === 'welcome' }"
      >
        <button type="button" class="close-chat" (click)="toggleChat()">
          Close Chat
        </button>
        <div #chatHistoryContainer class="chat-messages">
          <ng-container *ngIf="chatHistory?.length; else noChatHistory">
            <div
              class="message"
              *ngFor="let chat of chatHistory"
              [ngClass]="{
                sender: chat.sender === 'user',
                receiver: chat.sender === 'server'
              }"
            >
              {{ chat.message }}
            </div>
          </ng-container>

          <ng-template #noChatHistory>
            <span class="no-chat-history"> No Conversations! </span>
          </ng-template>
        </div>
      </div>

      <!-- Chat Input Area -->
      <div class="chat-input">
        <input
          type="text"
          [(ngModel)]="inputText"
          placeholder="Type Something"
          (input)="checkInput()"
          (keydown.enter)="submitSpeech()"
        />
        <button (click)="submitSpeech()" [disabled]="!inputText.trim().length">
          <img src="assets/images/svg/top-arrow.svg" alt="Send Icon" />
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isPermission && isActiveStream">
  <div class="permission-model">
    <div class="model-content">
      <p>
        Will you allow <span>{{ baseUrl }}</span> to use your microphone?
      </p>
      <img width="120" height="" src="assets/images/mic-animation.gif" alt="" />
      <p>Microphone to share:</p>
      <button class="common-button" (click)="getMicPermission()">Ok</button>
    </div>
  </div>
</ng-container>

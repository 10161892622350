import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DELAY_TIME } from '@constants/common';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [NgIf],
  template: `
    <div *ngIf="message" class="notification">
      {{ message }}
    </div>
  `,
  styles: [
    `
      .notification {
        position: fixed;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #0077b6;
        color: white;
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        animation: fade-in-out 4s forwards;
        z-index: 1000;
      }

      @keyframes fade-in-out {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    `,
  ],
})
export class NotificationComponent implements OnInit {
  message: string | null = null;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.notification$.subscribe((message) => {
      this.message = message;
      setTimeout(() => (this.message = null), DELAY_TIME['4000_MS']);
    });
  }
}
